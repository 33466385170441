<template>
    <router-view/>
</template>

<script>
export default {
name: 'Administration',
};
</script>

<style scoped>

</style>
